<template>
	<S2SForm v-if="client" :title="`${client[0].description} - Summary`" fluid>
		<div class="title text-center pa-5">Quick Overview</div>
		<S2SCard>
			<v-row>
				<v-col cols="6" xs="12" class="text-center">
					<div class="title">Last Removal</div>
					{{ formatDate(safesSummaryData.lastRemoval) }}
				</v-col>
				<v-col cols="6" xs="12" class="text-center">
					<div class="title" for="">Last Event</div>
					{{ formatDate(safesSummaryData.lastEvent) }}
				</v-col>
			</v-row>
		</S2SCard>
		<v-spacer class="pa-5"></v-spacer>

		<div class="title text-center pa-5">Unmatched Bank Statements</div>
		<v-data-table :headers="statementHeader" :items="safesSummaryData.unMatchedBankStatement" class="elevation-1" :loading="status.loading">
			<template v-slot:item="{ item }">
				<tr>
					<td class="text-xs-left" v-for="header in statementHeader" :key="header.id">{{ formatColumn(header, item) }}</td>
				</tr>
			</template>
		</v-data-table>
		<v-spacer class="pa-5"></v-spacer>

		<div class="title text-center pa-5">Outstanding Bags</div>
		<v-data-table :headers="bagsHeader" :items="safesSummaryData.outStandingBags" class="elevation-1" :loading="status.loading">
			<template v-slot:item="{ item }">
				<tr>
					<td class="text-xs-left" v-for="header in bagsHeader" :key="header.id">{{ formatColumn(header, item) }}</td>
				</tr>
			</template>
		</v-data-table>
		<v-spacer class="pa-5"></v-spacer>

		<div class="title text-center pa-5">Bag In Safe</div>
		<v-data-table :headers="bagsHeader" :items="safesSummaryData.bagInSafe" class="elevation-1" :loading="status.loading">
			<template v-slot:item="{ item }">
				<tr>
					<td class="text-xs-left" v-for="header in bagsHeader" :key="header.id">{{ formatColumn(header, item) }}</td>
				</tr>
			</template>
		</v-data-table>
		<v-spacer class="pa-5"></v-spacer>

		<div class="title text-center pa-5">Credits</div>
		<v-data-table :headers="creditsHeader" :items="safesSummaryData.credits" class="elevation-1" :loading="status.loading">
			<template v-slot:item="{ item }">
				<tr>
					<td v-for="header in creditsHeader" :key="header.id">
						{{ formatColumn(header, item) }}
					</td>
				</tr>
			</template>
		</v-data-table>
		<v-spacer class="pa-5"></v-spacer>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";
import { SafeSummary, UnMatchedBankStatement, BagSummary, Credits, Client } from "@/store/modules/safes";
import { Status } from "@/store/utils";

export default Vue.extend({
	name: "SafesSummary",

	props: {
		clientId: {
			type: String,
			default: "0"
		}
	},

	data: function() {
		return {
			safesSummaryData: {},
			client: {},
			statementHeader: [
				{ text: "ID", value: "id" },
				{ text: "Statement Date", value: "statementdate", type: "dateTime" },
				{ text: "Description", value: "description" },
				{ text: "Branch Code", value: "branchcode" },
				{ text: "Amount", value: "amount", type: "currency" }
			],

			bagsHeader: [
				{ text: "ID", value: "id" },
				{ text: "Bag Serial", value: "bagserialnumber" },
				{ text: "Amount", value: "amount", type: "currency" },
				{ text: "Last Event", value: "lastevent", type: "dateTime" },
				{ text: "Removal Amount", value: "removalamount", type: "currency" },
				{ text: "Removal Date", value: "removaldate", type: "dateTime" },
				{ text: "Removal Seal", value: "removalSeal" }
			],

			creditsHeader: [
				{ text: "ID", value: "id" },
				{ text: "Transaction Date", value: "transactiondate", type: "dateTime" },
				{ text: "Collection Unit", value: "collectionunit" },
				{ text: "Amount", value: "amount", type: "currency" }
			]
		};
	},

	computed: {
		status: function() {
			return this.$store.state.safes.status;
		},
		getSummary: function() {
			return this.$store.getters["safes/getSummary"];
		},
		getClients: function() {
			return this.$store.getters["safes/getClients"];
		}
	},

	created: function() {
		this.$store.dispatch("safes/fetchClients");
		this.$store.dispatch("safes/fetchSafeSummary", +this.$props.clientId);
		window.scrollTo(0, 0);
	},

	watch: {
		getSummary: function(newVal) {
			this.safesSummaryData = {};
			this.$nextTick(function() {
				this.safesSummaryData = newVal;
			});
		},
		getClients: function(newVal) {
			this.$nextTick(function() {
				this.client = newVal.filter(val => {
					return val.id === +this.$props.clientId;
				});
			});
		}
	},

	methods: {
		formatColumn(header, item) {
			return Utils.formatColumn(header, item);
		},
		formatDate(val) {
			return Utils.formatText(val, Utils.TextType.DATE_TIME);
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
