var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.client)?_c('S2SForm',{attrs:{"title":((_vm.client[0].description) + " - Summary"),"fluid":""}},[_c('div',{staticClass:"title text-center pa-5"},[_vm._v("Quick Overview")]),_c('S2SCard',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","xs":"12"}},[_c('div',{staticClass:"title"},[_vm._v("Last Removal")]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.safesSummaryData.lastRemoval))+" ")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6","xs":"12"}},[_c('div',{staticClass:"title",attrs:{"for":""}},[_vm._v("Last Event")]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.safesSummaryData.lastEvent))+" ")])],1)],1),_c('v-spacer',{staticClass:"pa-5"}),_c('div',{staticClass:"title text-center pa-5"},[_vm._v("Unmatched Bank Statements")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.statementHeader,"items":_vm.safesSummaryData.unMatchedBankStatement,"loading":_vm.status.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.statementHeader),function(header){return _c('td',{key:header.id,staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.formatColumn(header, item)))])}),0)]}}],null,false,2095362087)}),_c('v-spacer',{staticClass:"pa-5"}),_c('div',{staticClass:"title text-center pa-5"},[_vm._v("Outstanding Bags")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.bagsHeader,"items":_vm.safesSummaryData.outStandingBags,"loading":_vm.status.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.bagsHeader),function(header){return _c('td',{key:header.id,staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.formatColumn(header, item)))])}),0)]}}],null,false,3356095349)}),_c('v-spacer',{staticClass:"pa-5"}),_c('div',{staticClass:"title text-center pa-5"},[_vm._v("Bag In Safe")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.bagsHeader,"items":_vm.safesSummaryData.bagInSafe,"loading":_vm.status.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.bagsHeader),function(header){return _c('td',{key:header.id,staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm.formatColumn(header, item)))])}),0)]}}],null,false,3356095349)}),_c('v-spacer',{staticClass:"pa-5"}),_c('div',{staticClass:"title text-center pa-5"},[_vm._v("Credits")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.creditsHeader,"items":_vm.safesSummaryData.credits,"loading":_vm.status.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.creditsHeader),function(header){return _c('td',{key:header.id},[_vm._v(" "+_vm._s(_vm.formatColumn(header, item))+" ")])}),0)]}}],null,false,615560433)}),_c('v-spacer',{staticClass:"pa-5"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }